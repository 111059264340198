import { ClientTranslation } from '@/models/ClientTranslation'
import { Group } from '@/models/Group'
import { User } from '@/models/User'

/* eslint-disable camelcase */
export class Client {
  id: string | null;
  user_id: string | null;
  user: User | null;
  translations: ClientTranslation[];
  groups: Group[];
  address_number: string | null;
  post_code: string | null;
  main_email: string | null;
  tax_identification_number: string | null;
  identity_card: string | null;
  main_phone: string | null;
  secondary_phone: string | null;
  fax: string | null;
  age: number | null;
  found_from: string | null;
  profession: string | null;
  office_id: string | null;
  date_created: number
  date_updated: number

  constructor(data: any = {}) {
    this.id = data.id || null
    this.user_id = data.user_id || null
    this.user = data.user || null
    this.translations = data.translations || []
    this.groups = data.groups || []
    this.address_number = data.address_number || null
    this.post_code = data.post_code || null
    this.main_email = data.main_email || null
    this.tax_identification_number = data.tax_identification_number || null
    this.identity_card = data.identity_card || null
    this.main_phone = data.main_phone || null
    this.secondary_phone = data.secondary_phone || null
    this.fax = data.fax || null
    this.office_id = data.office_id || null
    this.age = data.age || null
    this.found_from = data.found_from || null
    this.profession = data.profession || null
    this.date_created = data.date_created || null
    this.date_updated = data.date_updated || null
  }
}
