

















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { convertToArrayTranslations, convertToMapTranslations, locales } from '@/utils'
import { ClientTranslation } from '@/models/ClientTranslation'
import { foundFromList, professions } from '@/utils/property'

@Component({
  name: 'Client'
})

export default class extends Vue {
  @Prop({ required: true }) private client: any
  @Prop({ required: false, default: () => ['ic', 'address', 'fax', 'notes', 'tin'] }) private exclude!: string[]

  private language = 'el'
  private translations = convertToMapTranslations(this.$props.client.translations || [], ClientTranslation)
  private foundFromList = foundFromList
  private professions = professions
  private atLeastOne = (rule: any, value: string, callback: Function) => {
    const isValid = locales.some((language) => {
      return this.translations[language][rule.field]
    })
    return isValid ? callback() : callback(this.$t('form.isRequired'))
  }

  private rules = {
    first_name: [{
      validator: this.atLeastOne,
      trigger: 'blur'
    }],
    last_name: [{
      validator: this.atLeastOne,
      trigger: 'blur'
    }]
  }

  private normalizeItemForSave() {
    this.client.user = null
    this.client.found_from = this.client.found_from || null
    this.client.profession = this.client.profession || null
    this.client.translations = convertToArrayTranslations(
      this.translations,
      ClientTranslation
    )
  }
}
