/* eslint-disable camelcase */
export class Call {
  id: string | null;
  property_id: string | null;
  property: string | null;
  user_id: string | null;
  user: string | null;
  office_id: string | null;
  client_id: string | null;
  client: string | null;
  notes: string | null;
  call_date: string | null;
  owner: string | null;
  meta: any;

  constructor(data: any = {}) {
    this.id = data.id || null
    this.property_id = data.property_id || null
    this.property = data.property || null
    this.user_id = data.user_id || null
    this.user = data.user || null
    this.office_id = data.office_id || null
    this.client_id = data.client_id || null
    this.client = data.client || null
    this.notes = data.notes || null
    this.call_date = data.call_date || null
    this.owner = data.owner || null
    this.meta = data.meta || {}
  }
}
