import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getCalls = (data: any) =>
  request({
    method: 'post',
    url: '/api/calls/getCalls',
    data
  })

export const getCallsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/calls/getCallsCount',
    data
  })

export const getCall = (params: any) =>
  request({
    method: 'get',
    url: '/api/calls/getCall',
    params
  })

export const saveCall = (data: any) =>
  request({
    method: 'post',
    url: '/api/calls/saveCall',
    data
  })

export const saveCallBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/calls/saveCallBulk',
    data
  })

export const deleteCall = (params: any) =>
  request({
    method: 'get',
    url: '/api/calls/deleteCall',
    params
  })

export const deleteCallBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/calls/deleteCallBulk',
    data
  })

export const exportCalls = (clients: any[]) => {
  const fields = [
    {
      key: 'client',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.client)
      }
    },
    {
      key: 'user',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user)
      }
    },
    {
      key: 'property',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.property, ['full_code'])
      }
    },
    {
      key: 'call_date',
      type: 'timestamp'
    },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]

  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, clients),
    'call-list'
  )
}
