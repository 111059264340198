













































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  deleteCall,
  getCall,
  saveCall
} from '@/api/calls'
import Sticky from '@/components/Sticky/index.vue'
import ClientForm from '@/components/Client/index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { confirmDialog, errorMsg, getTranslations, successMsg, validateForm } from '@/utils'
import { getRemoteProperties, getRemoteClients } from '@/api/helpers'
import { Form } from 'element-ui'
import { Call } from '@/models/Call'
import { Client } from '@/models/Client'

@Component({
  name: 'CallDetail',
  components: {
    Sticky,
    ClientForm
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private loading = false
  private skeletonLoader = false
  private propertiesLoading = false
  private clientsLoading = false
  private getTranslations = getTranslations
  private properties: any[] = []
  private clients: any[] = []
  private property: any = null
  private call = new Call({
    office_id: UserModule.officeId,
    call_date: new Date().getTime(),
    meta: {
      client: new Client()
    }
  })

  created() {
    if (this.isEdit && this.$route.params.id) {
      this.getItem(this.$route.params.id).then(() => {
        this.property = { id: this.call.property_id || null }
        if (this.call.property) {
          this.properties = [this.call.property]
        }
        if (this.call.client) {
          this.clients = [this.call.client]
        }
      })
    } else {
      this.call.user_id = UserModule.id
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private extractClientForm() {
    const client = this.$refs.client as any
    if (!client) return null
    return client.$refs.cardForm as Form
  }

  private async getItem(id: string) {
    try {
      this.skeletonLoader = true
      const { data } = await getCall({ id })
      this.call = data
    } catch (err) {
      await errorMsg('api.entityNotFound')
    } finally {
      this.skeletonLoader = false
    }
  }

  private async getRemoteClientsList(query: string) {
    this.clientsLoading = true
    this.clients = await getRemoteClients(query)
    this.clientsLoading = false
  }

  private async getRemotePropertiesList(query: string) {
    this.propertiesLoading = true
    this.properties = await getRemoteProperties(query)
    this.propertiesLoading = false
  }

  private async deleteItem() {
    try {
      const [data] = await confirmDialog('actions.apiDelete')
      if (!data) return
      await deleteCall({
        id: this.call.id
      })
      await successMsg('actions.apiDeleteSuccess')
      return this.$router.push('/call/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private onPropertyChange(item: any) {
    if (item) {
      this.call.property_id = item.id
      this.call.user_id = item.user_id
      this.call.owner = item.user_id
    } else {
      this.call.property_id = null
      this.call.user_id = UserModule.id
      this.call.owner = UserModule.id
    }
  }

  private normalizeItemForSave() {
    const client = this.$refs.client as any
    if (client) client.normalizeItemForSave()
    this.call.property = null
    this.call.client = null
    this.call.user = null
    this.call.user_id = this.call.user_id || UserModule.id
    this.call.owner = this.call.owner || UserModule.id
  }

  private async validateForms() {
    const [isCallFormValid] = await validateForm(this.$refs.callForm as Form)
    if (!isCallFormValid) return false
    const client = this.extractClientForm()
    if (client) {
      const [isClientFormValid] = await validateForm(client)
      return isClientFormValid
    }
    return true
  }

  private async saveItem() {
    try {
      const valid = await this.validateForms()
      if (!valid) return errorMsg('form.formErrors')
      this.loading = true
      this.normalizeItemForSave()
      const { data } = await saveCall(this.call)
      if (!this.isEdit) return this.$router.push('/call/edit/' + data.id)
      await successMsg('actions.apiSuccessSave')
    } catch (err) {
      await errorMsg('api.serverError')
    } finally {
      this.loading = false
    }
  }
}
