/* eslint-disable camelcase */
export class ClientTranslation {
  id: string | null;
  locale: string;
  notes: string;
  first_name: string;
  last_name: string;
  address: string;

  constructor({ id = null, locale = '', notes = '', first_name = '', last_name = '', address = '' } = {}) {
    this.id = id
    this.locale = locale
    this.notes = notes
    this.first_name = first_name
    this.last_name = last_name
    this.address = address
  }
}
